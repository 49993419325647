import React from "react";
import { Box } from "@mui/material";
import { GameDataProvider } from "./providers/GameDataProvider";
import PrivateRoutes from "./routes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
function App() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "#E9E6E6",
        background: "linear-gradient(to right, #E9E6E6, #fffcfc)",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GameDataProvider>
          <PrivateRoutes />
        </GameDataProvider>
      </LocalizationProvider>
    </Box>
  );
}

export default App;
