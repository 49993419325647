import { useEffect } from "react";
import useRefreshToken, { setAuth } from "./useRefreshToken";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Navigate } from "react-router-dom";
import { getAuth } from "@firebase/auth";
import { auth } from "../firebase";
import { routeConstant } from "../common/appConstant";
// import PageRoutes from "../utils/constants";

export type authDetailsType = {
  AccessToken: string;
  RefreshToken?: string;
  UserID?: string | number;

  FirstName?: string;
  LastName?: string;

  Email?: string;
};

export const getCurrentAuth = () => {
  const localAuth = localStorage.getItem("auth");
  const auth: authDetailsType = localAuth
    ? JSON.parse(localStorage.getItem("auth") || "")
    : {};
  return auth;
};

export const Logout = () => {
  localStorage.removeItem("auth");
};
const axiosInstance = axios.create();
const refresh = async () => {
  try {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const refreshedToken = await currentUser.getIdToken(true);
      // Handle the refreshed token as needed
      console.log("Refreshed access token:", refreshedToken);
      setAuth({
        AccessToken: refreshedToken,
      });
      return refreshedToken;
    }
  } catch (error: any) {
    // dispatch(logout());

    Logout();
    window.location.href = `${routeConstant.SIGN_IN}`;
  }
};
// var auth = useTypedSelector(getAuthState, shallowEqual);
// const dispatch = useDispatch();
let flag = false;
const refreshExpiredTokenClosure = () => {
  let isCalled = false;
  let runningPromise: any = undefined;
  return () => {
    if (isCalled && flag) {
      return runningPromise;
    } else {
      isCalled = true;
      flag = true;
      runningPromise = refresh();

      return runningPromise;
    }
  };
};

// stores the function returned by refreshExpiredTokenClosure
function shouldRetry(config: any) {
  return config.retries.count < 3;
}
const refreshExpiredToken = refreshExpiredTokenClosure();

axiosInstance.interceptors.request.use(
  // @ts-ignore: Unreachable code error
  (config: AxiosRequestConfig) => {
    const auth = getCurrentAuth();
    if (!config.headers!["Authorization"]) {
      config.headers!["Authorization"] = `bearer ${auth?.AccessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    error.config.retries = error.config.retries || {
      count: 0,
    };
    const originalRequest = error.config;

    // logout user's session if refresh token api responds 401 UNAUTHORIZED

    // if request fails with 401 UNAUTHORIZED status and 'Token has expired' as response message
    // then it calls the api to generate new access token
    console.log(error.response);
    if (
      error.response.status === 401 ||
      (error.response.status === 400 &&
        error.response.data?.error?.message === "Auth Failed" &&
        shouldRetry(error.config))
    ) {
      error.config.retries.count += 1;
      const updatedToken = await refreshExpiredToken();
      if (!updatedToken) {
        Logout();
        window.location.href = `${routeConstant.SIGN_IN}`;
      } else {
        originalRequest.headers["Authorization"] = `bearer ${updatedToken}`;
        flag = false;
        return axiosInstance(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
