export const routeConstant = {
  SIGN_IN: "/signin",
  SCHEDULES: "/schedules",

  MAIN: "/main/:id",
  EVENTS: "/events/:id",
  PENALTIES: "/penalties/:id",
  SHOTS: "/shots/:id",
  HOME_TEAM: "/home-team/:id",
  AWAY_TEAM: "/away-team/:id",
  PRE_GAME: "/pre-game/:id",
  POST_GAME: "/post-game/:id",

  PENALTY: "/main/:id/:homeOrAway/penalty",
  GOAL: "/main/:id/:homeOrAway/goal",
  PENALTY_SHOT: "/main/:id/:homeOrAway/penalty-shot",
  GOALIES: "/main/:id/:homeOrAway/goalies",
  LINE_UP: "/main/:id/:homeOrAway/lineup",
  SHOT_SAVE: "/main/:id/:homeOrAway/shot-save/:minutes/:seconds/:period",
  GOALIE_OUT: "/main/:id/:homeOrAway/goalie-out",

  EDIT_GOAL: "/edit/goal/:id",
  EDIT_SHOT: "/edit/shot/:id",

  EMPTY: "/",
};
