export const getQueryParams = (url: string) => {
    const params: any = {};
    const queryString = url.split('?')[1];
    if (!queryString) return params;
  
    const pairs = queryString.split('&');
    pairs.forEach((pair: any) => {
      const [key, value] = pair.split('=');
      params[key] = value;
    });
  
    return params;
  }