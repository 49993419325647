import React, { useContext, lazy, Suspense } from "react";
import { Box, Typography } from "@mui/material";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { routeConstant } from "../common/appConstant";
import ResponsiveAppBar from "../components/Common/Header";
import GoalEntry from "../pages/GoalEntry";
import LineUp from "../pages/LineUp";
import PenaltyEntry from "../pages/PenaltyEntry";
import PenaltyShotEntry from "../pages/PenaltyShotEntry";
import AuthContext from "../providers/AuthProvider";

// Lazy load the components
const SignIn = lazy(() => import("../pages/SignIn"));
const ScheduleList = lazy(() => import("../pages/ScheduleList"));
const Main = lazy(() => import("../pages/Main"));
const ShotEntry = lazy(() => import("../pages/ShotEntry"));
const Events = lazy(() => import("../pages/Events"));
const ShotList = lazy(() => import("../pages/ShotList"));
const PreGame = lazy(() => import("../pages/PreGame"));
const PostGame = lazy(() => import("../pages/PostGame"));
const NotFound = lazy(() => import("../pages/NotFound"));
const PrivateRoutes = () => {
  const authCtx = useContext(AuthContext);

  const FullScreenSkeleton = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography style={{ marginTop: 20, fontSize: 18, color: "#0A2068" }}>
        Loading...
      </Typography>
    </Box>
  );

  return (
    <Router>
      {authCtx.isLoggedIn && <ResponsiveAppBar />}
      <Box sx={{ p: 2 }}>
        <Suspense fallback={<FullScreenSkeleton />}>
          <Routes>
            <Route path={routeConstant.SIGN_IN} element={<SignIn />} />
            {!authCtx.isLoggedIn ? (
              <Route
                path="*"
                element={<Navigate to={routeConstant.SIGN_IN} replace />}
              />
            ) : (
              <>
                <Route
                  path={routeConstant.SCHEDULES}
                  element={<ScheduleList />}
                />
                <Route path={routeConstant.MAIN} element={<Main />} />
                {/* <Route path={routeConstant.LINE_UP} element={<LineUp />} /> */}
                <Route path={routeConstant.GOAL} element={<GoalEntry />} />
                {/* <Route
                path={routeConstant.PENALTY}
                element={
                  <PenaltyEntry
                    restPlayers={[
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                      { jersey: 32, name: "Alex Wilson" },
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                      { jersey: 32, name: "Alex Wilson" },
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                      { jersey: 32, name: "Alex Wilson" },
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                    ]}
                  />
                }
              /> */}
                {/* <Route
                path={routeConstant.PENALTY_SHOT}
                element={
                  <PenaltyShotEntry
                    restPlayers={[
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                      { jersey: 32, name: "Alex Wilson" },
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                      { jersey: 32, name: "Alex Wilson" },
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                      { jersey: 32, name: "Alex Wilson" },
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                    ]}
                  />
                }
              /> */}
                <Route path={routeConstant.SHOT_SAVE} element={<ShotEntry />} />
                <Route path={routeConstant.EVENTS} element={<Events />} />
                <Route path={routeConstant.SHOTS} element={<ShotList />} />
                <Route path={routeConstant.PRE_GAME} element={<PreGame />} />
                <Route path={routeConstant.POST_GAME} element={<PostGame />} />
                {/* <Route
                path={routeConstant.EDIT_GOAL}
                element={
                  <GoalEntry
                    restPlayers={[
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                      { jersey: 32, name: "Alex Wilson" },
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                      { jersey: 32, name: "Alex Wilson" },
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                      { jersey: 32, name: "Alex Wilson" },
                      { jersey: 1, name: "John Doe" },
                      { jersey: 23, name: "Jane Smith" },
                      { jersey: 5, name: "Mike Johnson" },
                      { jersey: 14, name: "Sarah Davis" },
                    ]}
                  />
                }
              /> */}
                {/* <Route path={routeConstant.EDIT_SHOT} element={<ShotEntry />} /> */}
                <Route path="*" element={<NotFound />} />
                <Route
                  path={routeConstant.EMPTY}
                  element={<Navigate to={routeConstant.SCHEDULES} replace />}
                />
              </>
            )}
          </Routes>
        </Suspense>
      </Box>
    </Router>
  );
};

export default PrivateRoutes;
