import React from "react";
import { Card, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

type Props = {
  jersey: number;
  name: string;
  cardWidth?: string;
  onClick?: any;
  style?: any;
  onItemRemove?: any;
  isClosable?: boolean;
};

const PlayerCard = ({
  jersey,
  name,
  cardWidth,
  onClick,
  style,
  onItemRemove,
  isClosable = true,
}: Props) => {
  return (
    <Card
      variant="outlined"
      sx={{
        border: "2px solid #000000",
        borderRadius: "10px",
        backgroundColor: "#f5f5f5",
        margin: "0.5rem 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
        padding: "0.6rem 1.5rem",
        position: "relative",
        cursor: "default",
        width: cardWidth ? cardWidth : "100%",
        "@media (max-width: 600px)": {
          padding: "0.6rem 1rem",
        },
        "@media (max-width: 768px)": {
          padding: "0.6rem 0.5rem",
        },
        ...style,
      }}
      onClick={onClick}
    >
      <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 600 }}>
        {jersey}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 400 }}>
        {name}
      </Typography>
      <CancelIcon
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          color: "#343942",
          p: 0,
          cursor: "pointer",
          display: isClosable ? "" : "none",
        }}
        onClick={onItemRemove}
      />
    </Card>
  );
};

export default PlayerCard;
