import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { routeConstant } from "../../common/appConstant";
import { LogoutOutlined } from "@mui/icons-material";
import AuthContext from "../../providers/AuthProvider";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { getCurrentAuth } from "../../hooks/http-server";

const pages = [
  { title: "Main", route: "/main" },
  { title: "Events", route: "/events" },
  // { title: "Penalties", route: "/penalties" },
  { title: "Shots", route: "/shots" },
  // { title: "Home Team", route: "/home-team" },
  // { title: "Away Team", route: "/away-team" },
  { title: "Pre-Game", route: "/pre-game" },
  { title: "Post-Game", route: "/post-game" },
];

function ResponsiveAppBar() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const game_id = JSON.parse(localStorage.getItem("game_id") || "0") || 0;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const mobile = useMediaQuery("(max-width:900px)");
  const currentAuth = getCurrentAuth();

  const drawer = (
    <Box
      onClick={() => setMobileOpen((prev) => !prev)}
      sx={{ textAlign: "center" }}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              mr: 2,
              fontSize: "20px",
              textDecoration: "none",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate(routeConstant.SCHEDULES)}
          >
            Scoring
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {pages.map((item: { title: string; route: string }) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton
              sx={{
                textAlign: "center",
                backgroundColor:
                  location.pathname === `${item.route}/${game_id}`
                    ? "#CCA5F7"
                    : "#ffffff",
                fontWeight:
                  location.pathname === `${item.route}/${game_id}` ? 700 : 100,
                display:
                  location.pathname === routeConstant.SCHEDULES
                    ? "none"
                    : "block",
                "&:hover": {
                  backgroundColor: "#DDC6F8",
                },
                "&:focus": {
                  backgroundColor: "#CCA5F7",
                  fontWeight: 700,
                },
              }}
              onClick={() => navigate(`${item.route}/${game_id}`)}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                authCtx.handleLogOut();
                navigate("/");
              }}
            >
              <ListItemText primary={"Sign out"} />
            </ListItemButton>
          </ListItem>
        </List>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#ffffff", color: "#000000", maxHeight: "60px" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                display: { sm: "flex" },
                "@media(min-width: 900px)": {
                  display: "none",
                },
              }}
            >
              <IconButton
                size="large"
                aria-label="drawer open"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setMobileOpen((prev) => !prev)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                fontSize: "1.2rem",
                display: "flex",
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate(routeConstant.SCHEDULES)}
            >
              <img
                src={require("../../common/assets/NAHL.png")}
                alt="Scoring"
                style={{
                  width: 50,
                  height: 40,
                  marginRight: 5,
                  color: "#000000",
                }}
              />
              Scoring
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { sm: "flex" },
                mx: 1.5,
                "@media(max-width: 900px)": {
                  display: "none",
                },
              }}
            >
              {pages.map((page: any, index: number) => (
                <Button
                  key={index}
                  onClick={() => {
                    navigate(`${page.route}/${game_id}`);
                  }}
                  sx={{
                    color:
                      location.pathname === `${page.route}/${game_id}`
                        ? "#ffffff"
                        : "#000000",
                    backgroundColor:
                      location.pathname === `${page.route}/${game_id}`
                        ? "#0a2ea6"
                        : "#ffffff",
                    fontWeight:
                      location.pathname === `${page.route}/${game_id}`
                        ? 700
                        : 100,
                    display:
                      location.pathname === routeConstant.SCHEDULES
                        ? "none"
                        : "block",
                    "&:hover": {
                      backgroundColor: "#b3b3b3",
                    },
                    "&:focus": {
                      backgroundColor: "#0a2ea6",
                      fontWeight: 700,
                    },
                  }}
                >
                  {page.title}
                </Button>
              ))}
            </Box>
            {!mobile && (
              <>
                <Typography sx={{ marginRight: 2 }}>
                  Hi, {currentAuth.Email}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0A2068",
                    "&:hover": {
                      backgroundColor: "#010c33",
                    },
                  }}
                  onClick={() => authCtx.handleLogOut()}
                >
                  <LogoutOutlined />
                </Button>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen((prev) => !prev)}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "@media(max-width: 900px)": {
              display: "block",
            },
            display: { sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 240,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
export default ResponsiveAppBar;
