import React from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Box, Button, useMediaQuery } from "@mui/material";
import theme from "../../common/style/config/theme";

const FooterButtons = ({
  onBackClick,
  onNextClick,
  onSaveClick,
  onClearClick,
  isBackDisable,
  isClearDisable,
  isSaveDisable,
  isNextDisable,
}: any) => {
  const screen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        py: 4,
        px: { md: 6, sm: 0 },
      }}
    >
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#0A2068",
          "&:hover": {
            backgroundColor: "#010c33",
          },
        }}
        startIcon={<KeyboardDoubleArrowLeftIcon />}
        onClick={() => {
          onBackClick ? onBackClick() : window.history.back();
        }}
        disabled={isBackDisable}
      >
        {screen ? "" : "Back"}
      </Button>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#680a0a",
            "&:hover": {
              backgroundColor: "#330101",
            },
          }}
          startIcon={<RemoveCircleOutlineIcon />}
          onClick={onClearClick}
          disabled={isClearDisable}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0a681e",
            "&:hover": {
              backgroundColor: "#01330a",
            },
          }}
          startIcon={<EnhancedEncryptionIcon />}
          onClick={onSaveClick}
          disabled={isSaveDisable}
        >
          Save
        </Button>
      </Box>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#0A2068",
          "&:hover": {
            backgroundColor: "#010c33",
          },
        }}
        endIcon={<KeyboardDoubleArrowRightIcon />}
        onClick={onNextClick}
        disabled={isNextDisable}
      >
        {screen ? "" : "Next"}
      </Button>
    </Box>
  );
};

export default FooterButtons;
