import { useNavigate } from "react-router-dom";

// import { APIRoutes } from "../utils/constants";
import { authDetailsType, getCurrentAuth, Logout } from "./http-server";
import axios from "../utils/axios";
import { routeConstant } from "../common/appConstant";

export function setAuth(data: authDetailsType) {
  const oldAuth = getCurrentAuth();
  const tempAuth = { ...oldAuth, ...data };
  tempAuth.AccessToken = data.AccessToken;
  tempAuth.RefreshToken = data.RefreshToken;

  localStorage.setItem("auth", JSON.stringify(tempAuth));
}

const useRefreshToken = () => {
  const navigate = useNavigate();

  // var auth = useTypedSelector(getAuthState, shallowEqual);
  const auth = getCurrentAuth();

  const RefreshToken = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authentication: `Bearer ${auth.AccessToken}`,
      };
      const response = await axios.post(
        "",
        {
          RefreshToken: auth.RefreshToken,
        },
        { headers: headers }
      );
      if (response.data) {
        const body: authDetailsType = response.data.data;
        setAuth(body);
        return body.AccessToken;
      }
      return;
    } catch (error: any) {
      // dispatch(logout());
      Logout();
      navigate(routeConstant.SIGN_IN);
    }
  };
  return RefreshToken;
};

export default useRefreshToken;
