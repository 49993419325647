import React, { createContext, useContext, useRef, useState } from "react";
import { db2, makeGetRequest } from "../services/makeApiCall";
import AuthContext from "./AuthProvider";
import { Alert, Snackbar } from "@mui/material";
import { onValue, ref } from "firebase/database";
import { useLocation, useParams } from "react-router-dom";
import { routeConstant } from "../common/appConstant";

const GameDataContext = createContext<any>(null);
export const GameDataProvider = ({ children }: any) => {
  const authCtx = useContext(AuthContext);
  const [liveData, setLiveData] = useState<any>({});
  const refLis: any = useRef(null);
  const rink_refLis: any = useRef(null);
  const [isSkeleton, setIsSkeleton] = React.useState(false);
  const { id }: any = useParams();

  const [gameData, setGameData] = React.useState({});
  async function startup_new(channels: any, game_id: any) {
    if (!game_id) {
      return;
    }
    var i = 0;

    for (i = 0; i < channels.length; i++) {
      /* Firebase mode */
      if (channels[i].game_id > 0)
        var firebase_channel =
          "games/" + channels[i].channel + "/" + channels[i].game_id;
      else var firebase_channel = "rinks/" + channels[i].channel;

      var rink_ref = ref(db2, firebase_channel);
      rink_refLis.current = rink_ref;

      console.log("firebase Starting " + firebase_channel);
      (function (prefix, game) {
        var last_game_id = 0;
        var requested_game = game;
        if (requested_game) {
          // GetGameData(prefix + "_", requested_game);
          // GetRosterData(requested_game);
          last_game_id = requested_game;
        }

        refLis.current = onValue(rink_ref, (snapshot: any) => {
          const fb_data = snapshot.val();
          if (fb_data == null) return;
          const data = fb_data.clock_data;
          if (fb_data.clock_data == null) return;

          if (requested_game && data.game_id != requested_game) {
            return;
          }

          if (data.rosters == 1 || data.events == 1) {
            getGameDetailsLive(game_id);
          }

          setLiveData(data);
          // process_scoreboard_data(prefix + "_", data);
        });
      })(channels[i].prefix, channels[i].game_id);
    }
  }

  const getGameDetailsLive = async (game_id: any) => {
    let qurey: any = {
      game_id: game_id || id,
    };

    try {
      const res = await makeGetRequest(
        "get_game_center",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: game_id,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${game_id}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      setGameData(data);
    } catch (error) {}
  };
  const getGameInfo = async (game_id: any) => {
    localStorage.setItem("game_id", JSON.stringify(game_id));

    setIsSkeleton(true);
    const qurey: any = {
      game_id: game_id,
    };
    try {
      const response = await makeGetRequest(
        "get_game_center",
        new URLSearchParams(qurey).toString()
      );
      const data = {
        ...response?.game_center?.pregame,
        ...response?.game_center?.live,
        ...response?.game_center?.postgame,
        ...response?.game_center?.game_info,
      };
      const channel = {
        game_id: game_id,
        channel_type: 2,
        prefix: `${response?.game_center?.game_info?.game_channel}_${game_id}`,
        channel: response?.game_center?.game_info?.game_channel,
      };
      const array = [channel];

      window.location.pathname == routeConstant.MAIN.replace(":id", game_id) &&
        (await startup_new(array, game_id));

      setGameData(data);
      setIsSkeleton(false);
    } catch (error: any) {
      authCtx.setError({ isError: true, message: error.message });
      setIsSkeleton(false);
    }
  };

  return (
    <GameDataContext.Provider
      value={{
        gameData,
        setGameData,
        liveData,
        refLis,
        isSkeleton,
        rink_refLis,
        setIsSkeleton,
        getGameInfo,
      }}
    >
      <Snackbar
        open={authCtx.error.isError}
        autoHideDuration={2000}
        onClose={() => authCtx.setError({ isError: false, message: "" })}
      >
        <Alert severity="error">{authCtx.error.message}</Alert>
      </Snackbar>
      {children}
    </GameDataContext.Provider>
  );
};

export default GameDataContext;
