import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDlrMQ_KQi-O_j45DG8AAClMIvyn9y0H5A",
  authDomain: "tts-firebase-393711.firebaseapp.com",
  projectId: "tts-firebase-393711",
  storageBucket: "tts-firebase-393711.appspot.com",
  messagingSenderId: "785380654472",
  appId: "1:785380654472:web:0cd700cd4d792573320e3d",
  measurementId: "G-3W4R11ZHKC",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
