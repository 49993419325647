import React, { createContext, useEffect, useState } from "react";
import {
  EmailAuthProvider,
  confirmPasswordReset,
  getAuth,
  onIdTokenChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { Logout, authDetailsType, getCurrentAuth } from "../hooks/http-server";
import { setAuth } from "../hooks/useRefreshToken";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { auth } from "../firebase";

const AuthContext = createContext<any>(null);

export const AuthProvider = ({ children }: any) => {
  const [error, setError] = useState({ message: "", isError: false });
  const [success, setSuccess] = useState({ message: "", isSuccess: false });

  const [passwordRecoveryInProcess, setPasswordRecoveryProcess] =
    useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn") || "false")
  );

  useEffect(() => {
    let unsubscribe: any;
    if (!passwordRecoveryInProcess) {
      unsubscribe = onIdTokenChanged(auth, async (user: any) => {
        if (user) {
          console.log("Refreshed token:", user);
        }
        try {
          const currentUser = auth.currentUser;
          if (currentUser) {
            // const refreshedToken = await currentUser.getIdToken(true);
            // Handle the refreshed token as needed
            // console.log("Refreshed access token:", refreshedToken);
          }
        } catch (error) {
          console.error("Error refreshing access token:", error);
        }
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [passwordRecoveryInProcess]);

  const handleLogIn = (email: string, password: string) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential: any) => {
        // Signed in
        const tokenResponse: any = userCredential["_tokenResponse"];
        const data: authDetailsType = {
          AccessToken: tokenResponse.idToken,
          RefreshToken: tokenResponse.refreshToken,
          Email: tokenResponse.email,
        };
        setAuth(data);
        setIsLoggedIn(true);
        localStorage.setItem("isLoggedIn", JSON.stringify(true));
      })
      .catch((error) => {
        setIsLoggedIn(false);
        localStorage.setItem("isLoggedIn", JSON.stringify(false));
        const errorCode = error.code;
        const errorMessage = error.message;
        setError({ message: errorMessage, isError: true });
        console.error("Firebase Error:", errorCode, errorMessage);
      });
  };

  const handleLogOut = () => {
    // Sign out the user
    signOut(auth).then(() => {
      setIsLoggedIn(false);
      localStorage.setItem("isLoggedIn", JSON.stringify(false));
      localStorage.removeItem("game_id");
      setError({ message: "", isError: false });
      Logout();
    });
  };

  const handleForgotPassword = (email: string) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccess({
          message: "Forgot Password Link Sent Successfully",
          isSuccess: true,
        });
      })
      .catch((error) => {
        setError({ message: error.message, isError: true });
        console.error("Forgot Password Error:", error.code, error.message);
      });
  };

  const handlePasswordRecovery = async (
    email: string,
    oldPassword: string,
    newPassword: string
  ) => {
    setPasswordRecoveryProcess(true);
    const credential = EmailAuthProvider.credential(email, oldPassword);
    await signInWithEmailAndPassword(auth, email, oldPassword);
    if (auth.currentUser) {
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, newPassword)
        .then(() => {
          setPasswordRecoveryProcess(false);
          setSuccess({
            message: "Password recovered successfully.",
            isSuccess: true,
          });
        })
        .catch((error) => {
          setError({ message: error.message, isError: true });
          console.error("Password Recovery Error:", error.code, error.message);
          setPasswordRecoveryProcess(false);
        });
    }
    setPasswordRecoveryProcess(false);
  };

  const authToken = getCurrentAuth();
  useEffect(() => {
    if (!authToken.AccessToken) {
      handleLogOut();
    }
  }, [authToken.AccessToken]);

  return (
    <AuthContext.Provider
      value={{
        handleLogIn,
        handleLogOut,
        handleForgotPassword,
        handlePasswordRecovery,
        isLoggedIn,
        error,
        setError,
      }}
    >
      <Snackbar
        open={success.isSuccess}
        autoHideDuration={2000}
        onClose={() => setSuccess({ isSuccess: false, message: "" })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {success.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error.isError}
        autoHideDuration={2000}
        onClose={() => setError({ isError: false, message: "" })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">{error.message}</Alert>
      </Snackbar>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
